import {
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MapsService
} from 'src/app/services/maps.service';
import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { all_handlers } from './map.handlers';
import { Router } from '@angular/router';
// import { graphics_handler } from './maps.graphics';
import {
  environment
} from 'src/environments/environment';
import {
  GeoJSONSource
} from 'mapbox-gl';
import { encode } from 'punycode';
import { ActivatedRoute } from '@angular/router';
import { MapType } from '@angular/compiler';
// import { type } from 'os';
// import {
//   feature
// } from '@turf/turf';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})


export class MapsComponent implements OnInit {  

  isSideBarActive: boolean = true;
  classApplied = false;
  showSearchField: boolean = true;
  showAddressBar: boolean = false;
  showSearchDirection: boolean = false;
  showDetails: boolean = false;
  showCopyRightDetails: boolean = false;
  voiceSearchUniDirection: boolean = false;
  voiceSearchBiDirectionFrom: boolean = false;
  voiceSearchBiDirectionTo: boolean = false;
  shareApp: boolean = false;
  locationText: any;
  addressText: any;
  countryselect = 'IND';
  fromLocation: any // this is for displaying addipid
  toLocation: any // this is for displaying addipid
  fromRoute: any // this is for memorising wgs84 location
  toRoute: any // this is for memorising wgs84 location
  fromToFocus: any
  basemap: any
  encode_results: any;
  map: mapboxgl.Map;
  geojson: any;
  squareGrid: any;
  map_center_lat = 0;
  map_center_lng = 0;
  marker_a: any;
  marker_b: any;
  input_text_color = "red"
  horizantalLines:any;
  verticalLines:any;
  cell_polygon:any;
  floor_no:any;
  marker:any;
  type:any;
  enable_satellite:any;
  enable_street:any;
  
  constructor(private route:ActivatedRoute, private mapsService: MapsService, private allhandlers:all_handlers, private router:Router) {
    // this.getSearchLocation()
  }


  getFloor() {
    // hide the input floor elements from map canvas
   this.disable_floorBox()

    // hide the select box  
    let ele = document.getElementById('floorInput') as any
    this.floor_no = ele.value // take the floor number
    this.allhandlers.colorise(this,false,this.floor_no) //not encoding but adding extra value to already encoded output
    ele.value='';
    // ele.style.display = 'none';
    // document.getElementById('floorBtn').style.display = 'none';
    // ele.selectedIndex = 0
  }

 ngOnInit(): void {
  
  this.route.paramMap.subscribe(
    params=>{
      this.type = params.get('type')
    }
  )

  // check the global map type and accordingly switch the base maps
    
  if (this.type=="omap"){
    this.enable_satellite="osm-satellite-map";
    this.enable_street="osm-street-map";
  } else if (this.type=="gmap"){
    this.enable_satellite="google-satellite-map";
    this.enable_street="google-street-map";
  } else {
    this.enable_satellite="mapbox-satellite-map";
    this.enable_street="mapbox-street-map";
  }

 
  

    mapboxgl as typeof mapboxgl;
    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox.accessToken,
      container: 'map',
      style: this.type=="mmap" ? 'mapbox://styles/mapbox/streets-v8':'',
      zoom: 1,
      maxZoom:19
    });

     // change the cursor to pointer
     this.map.getCanvas().style.cursor = "pointer";
     // add marker div element
     const markerDiv = document.createElement('div');
     this.marker = new mapboxgl.Marker(markerDiv)
     markerDiv.style.height = '30px'
     markerDiv.style.width = '30px'
     markerDiv.style.backgroundImage = "url('assets/imgs/marker.png')"
     markerDiv.style.backgroundSize = "contain"

    
     
    this.map.setCenter([10, 20]);  

    if(this.type != "mmap"){ 
    this.map.addSource("google-street", { "type": "raster", "tiles": ["https://mt0.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}"], "tileSize": 256}); 
    this.map.addLayer({ "type": "raster", "id": 'google-street-map', "source": "google-street" });     

    this.map.addSource("google-satellite", { "type": "raster", "tiles": ["https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"], "tileSize": 256}); 
    this.map.addLayer({ "type": "raster", "id": 'google-satellite-map', "source": "google-satellite" }); 

    this.map.addSource("osm-satellite", { "type": "raster", "tiles": ["https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"], "tileSize": 256 }); 
    this.map.addLayer({ "type": "raster", "id": 'osm-satellite-map', "source": "osm-satellite" }); 

    this.map.addSource("osm-street", { "type": "raster", "tiles": ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"], "tileSize": 256 }); 
    this.map.addLayer({ "type": "raster", "id": 'osm-street-map', "source": "osm-street" });

    this.map.setLayoutProperty('google-satellite-map', 'visibility', 'none');
    this.map.setLayoutProperty('google-street-map', 'visibility', 'none');
    this.map.setLayoutProperty('osm-satellite-map', 'visibility', 'none');
    this.map.setLayoutProperty('osm-street-map', 'visibility', 'none');

    // reduce opacity of layers
    this.map.setPaintProperty('google-satellite-map', 'raster-opacity', 0.7 );
    this.map.setPaintProperty('google-street-map', 'raster-opacity', 0.7 );
    this.map.setPaintProperty('osm-satellite-map', 'raster-opacity', 0.7 );
    this.map.setPaintProperty('osm-street-map', 'raster-opacity', 0.7 );
    }

    let hoveredStateId = null as any
    this.map.on('load', () => {   
      
      // this.map.addImage('pulsing-dot', this.mapgraphics.get_pulsing_point(), { pixelRatio: 2 });
        // enable the street as per map type routing page   
        if(this.type != "mmap"){ 
          this.map.setLayoutProperty(this.enable_street, 'visibility', 'visible');
        }else {
          // add mapbox satellite to overlay on top of vector pbf
          this.map.addSource('mapbox-satellite', {type: 'raster',  url: 'mapbox://mapbox.satellite' });
          this.map.addLayer({'id': 'mapbox-satellite-map','type': 'raster','source': 'mapbox-satellite'}); 
          this.map.setLayoutProperty('mapbox-satellite-map', 'visibility', 'none'); 
        }


      this.map.addSource('dot-point', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [{
            'properties':{},
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [0, 0] // icon position [lng, lat]
            }
          }]
        }
      });


      this.map.addLayer({
        'id': 'layer-with-pulsing-dot',
        'type': 'symbol',
        'source': 'dot-point',
        'layout': {
        'icon-image': 'pulsing-dot'
        }
        });


      this.map.addSource('mesh', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
      this.map.addSource('cell', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
      this.map.addSource('selectedCell', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });

      // check if there is an enter text pressed in the main contenteditable div
      document.getElementById('loctext').addEventListener('keypress', (evt) => {
        // alert(evt.key)
        if (evt.key  === 'Enter') {
            evt.preventDefault();
        }
      });
      document.getElementById('loctext').addEventListener('input', (evt) => {
        const lastChar = document.getElementById('loctext').innerText[document.getElementById('loctext').innerText.length - 1];
        const isAlphabet = /^[a-zA-Z]+$/.test(lastChar);
        if (isAlphabet) {
          document.getElementById('loctext').style.fontSize = '20px';
        }
      });
// Lets add layers and include the above sources
      // this.map.addLayer({
      //   'id': 'layer-with-pulsing-dot',
      //   'type': 'symbol',
      //   'source': 'dot-point',
      //   'layout': {
      //     'icon-image': 'pulsing-dot'
      //   }
      // });
      // Add a new layer to visualize the Mesh made up of Multilinestring.
      this.map.addLayer({
        'id': 'super-mesh',
        'type': 'line',
        'source': 'mesh',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#a6a6a6',
          'line-width': 0.5,
          'line-opacity': 0
        }
      });

      this.map.addLayer({
        'id': 'super-cell',
        'type': 'fill',
        'source': 'cell',
        'paint': {
          'fill-color': 'black', // Set the default fill color
          'fill-opacity': 0.5
        }
      })

      this.map.addLayer({
        'id': 'super-selectedCell',
        'type': 'fill',
        'source': 'selectedCell',
        'paint': {
          'fill-color': '#C00000', // Set the default fill color
          'fill-opacity': 0.75
        }
      })


      // calling creategrid function to load the mesh made up of MultiLineStrings
      this.createGrids(this.map)

      this.map.on('moveend', () => {
          this.createGrids(this.map)
        })
        .on('zoomend', () => {
          this.createGrids(this.map);
          this.disable_floorBox();
          // console.log(this.map.getZoom())
        })
        .on('zoomstart',()=>{
          this.disable_floorBox();
        })

        this.map.on('click', (e) => {
          this.marker.remove();
          // change the mouse cursor icon to hand          
          if (this.map.getZoom() <= 17.5){
            this.get_encodea(e.lngLat.lng.toString(), e.lngLat.lat.toString())
            // new mapboxgl.Marker(el).setLngLat([e.lngLat.lng,e.lngLat.lat])
            this.marker.setLngLat([e.lngLat.lng,e.lngLat.lat]).addTo(this.map);  
            // perform reverse geocoding and get the address to format in our address area
            let clicked = [e.lngLat.lng,e.lngLat.lat] as any;
            // let address = this.geocode_location(clicked)          
          }
        })
        
        this.map.on('click', 'super-cell', (e) => {
          // document.getElementById('loctext').style.fontSize = 'xx-large';
          // add to the selected cell source
          (this.map.getSource('selectedCell') as GeoJSONSource).setData(turf.polygon([this.cell_polygon]))
          let center_point = this.allhandlers.get_centroid(turf.polygon([this.cell_polygon])) as any;
          // pan the map to the center based on the selected cell
          this.map.panTo(center_point.geometry.coordinates);
          setTimeout(()=>{
            // show the select box to select the floor number
          let coords = this.map.getCenter();
          // console.log(coords.lng, coords.lat)
          
          let point = this.map.project(new mapboxgl.LngLat( 0.00002252342+coords.lng,  0.00002252342+coords.lat));
          let selectbox = document.getElementById('selectGroupbox');
          selectbox.style.top = point.y + 'px';
          selectbox.style.left = point.x + 'px';
          selectbox.style.display = 'block';
          // hide the input floor elements from map canvas          
          this.enable_floorBox();


            // (this.map.getSource('layer-with-pulsing-dot') as GeoJSONSource).setData(turf.point([-75.343, 39.984]))
            
          }, 1000);
          
          // call the below function to get the encoding information
          this.allhandlers.getCellInfo(e,this)
          // perform reverse geocoding and get the address to format in our address area
          // let address = this.geocode_location(center_point.geometry.coordinates)
        });

    })

  }



createMeshGrids(output){
    // 1. Create a layer in Mapbox GL that displays the output MultiLineString we recieved from backend.
        (this.map.getSource('mesh') as GeoJSONSource).setData({
          "type": "FeatureCollection",
          "features": [{
            "type": "Feature",
            "properties": {},
            "geometry": {
              "coordinates": output['coordinates'],
              "type": "MultiLineString"
            }
          }]
        });
    // 2. Define a hover effect for the super-mesh layer
      this.map.on('mousemove', e => {
        (this.map.getSource('cell') as GeoJSONSource).setData({
          type: 'FeatureCollection',
          features: []
        })
        if (this.map.getZoom() <= 17.5){
          return;
        }
        // Change the cursor style to "pointer" when hovering over a line string
        this.map.getCanvas().style.cursor = 'pointer';
        // Find the nearest point on each line to the reference point (e)
        let ref_point = [e.lngLat.lng,e.lngLat.lat]
        let horizontalDistances = this.horizantalLines.map(function(line) {
          let nearestPoint = turf.nearestPointOnLine(turf.lineString(line), ref_point);
          return turf.distance(ref_point, nearestPoint.geometry.coordinates);
        });
        let verticalDistances = this.verticalLines.map(function(line) {
          let nearestPoint = turf.nearestPointOnLine(turf.lineString(line), ref_point);
          return turf.distance(ref_point, nearestPoint.geometry.coordinates);
        });

        // 3. Sort the distances to find the nearest lines
        horizontalDistances.sort();
        verticalDistances.sort();
        
        // 4. Get the nearest 2 horizontal and 2 vertical lines
        let nearestHorizontalLines = this.horizantalLines.filter(function(line) {
          let nearestPoint = turf.nearestPointOnLine(turf.lineString(line), ref_point);
          let distance = turf.distance(ref_point, nearestPoint.geometry.coordinates);
          return distance <= horizontalDistances[1];
        });
        let nearestVerticalLines = this.verticalLines.filter(function(line) {
          let nearestPoint = turf.nearestPointOnLine(turf.lineString(line), ref_point);
          let distance = turf.distance(ref_point, nearestPoint.geometry.coordinates);
          return distance <= verticalDistances[1];
        });
        
        // 5. loop over any vertical and horizantal lines and get the snapping points
        // horizontal lines are those horizantally spread |||| like this
        // Vertical lines are viceverca (its little tricks your mind)
        let coordinates = []
        for (let i = 0; i < nearestVerticalLines.length; i++) {
          for (let k = 0; k < nearestHorizontalLines.length; k++) {
            let lineY = turf.lineString(nearestVerticalLines[i]);            
            let lineH = turf.lineString(nearestHorizontalLines[k]); 
            let intersects = turf.lineIntersect(lineH, lineY);
            coordinates.push(intersects.features[0].geometry.coordinates);
          }
        }
        // 6. change the order of indexing and also add the first element once again at the end to create proper polygon out of this array
        [coordinates[2], coordinates[3]] = [coordinates[3], coordinates[2]];
        coordinates.push(coordinates[0])

        // check if the create polygon coordinates are same on mouse move. if so, no need to set the data to source. 
        if (this.cell_polygon === coordinates){
          return;
        }
        // if created polygon coordinates are not same as previous polygon on mouse move, then update the cell polygon and continue
        this.cell_polygon = coordinates;
        // 7. Create the polygon
        let polygon = turf.polygon([coordinates]);
        // 8. Add the polygon to the map as a fill layer
        (this.map.getSource('cell') as GeoJSONSource).setData(polygon)
      });     

}

  createGrids(map) {
    /* 
    This function creates mesh using bounding box after recieving the data
    */
    if (map.getZoom() > 17.5) {
      var map_bounds = {} as any;
      map_bounds = map.getBounds();
      let [xmin, ymin, xmax, ymax] = [map_bounds._sw.lng, map_bounds._sw.lat, map_bounds._ne.lng, map_bounds._ne.lat];
      this.get_grid(xmin, ymin, xmax, ymax);
    } else {
      (map.getSource('mesh') as GeoJSONSource).setData({
        type: 'FeatureCollection',
        features: []
      });
    }
  }
  directions(): void {
    
    this.showSearchField = !this.showSearchField;
    this.showSearchDirection = !this.showSearchDirection;
    this.showSearchDirection == true ? this.fromToFocus = undefined : null //always intiate to undefined when ever the directions button is clicked
    // this.showSearchDirection == false && this.marker_a != undefined ? (this.marker_a.remove(), this.marker_b.remove(), this.fromToFocus = undefined) : null
    this.showSearchDirection == false ? this.fromToFocus = undefined : null
  }

  toggle() {
    this.showDetails = !this.showDetails;
  }

  togglecopyright() {
    this.showCopyRightDetails = !this.showCopyRightDetails;
  }

  voiceSearchDirection() {
    this.voiceSearchUniDirection = !this.voiceSearchUniDirection
  }

  voiceSearchDirectionFrom() {
    this.voiceSearchBiDirectionFrom = !this.voiceSearchBiDirectionFrom;
  }

  voiceSearchDirectionTo() {
    this.voiceSearchBiDirectionTo = !this.voiceSearchBiDirectionTo;
  }

  shareApplication() {
    this.shareApp = !this.shareApp;
  }

  // call function to get the grid
  get_grid(xmin: string, ymin: string, xmax: string, ymax: string) {
    this.mapsService.getGrid(xmin, ymin, xmax, ymax).subscribe(
      (data: any) => {
        // 1. seperate horizantal and vertical lines here
        this.horizantalLines = [];
        this.verticalLines = [];
        for (var i = 0; i < data["coordinates"].length; i++) {
          var line = data["coordinates"][i];
          if (line[0][1] === line[1][1]) {
            this.horizantalLines.push(line);
          } else if (line[0][0] === line[1][0]) {
            this.verticalLines.push(line);
          }
        }
        this.createMeshGrids(data)

      },
      (error: any) => {
        alert("Please Navigate to home and Authenticate.")
        window.location.href = 'https://verifypinid.auth.us-east-1.amazoncognito.com/login?client_id=6qd3i6390mia6qpdes4pemq26h&redirect_uri='+environment.redirect_url+'&response_type=code';
      }
      
    )
  }


// set the color of pincode
setColor(){
  this.allhandlers.colorise(this,false);
}

clear(event: KeyboardEvent){
  if (event.key === 'Escape') {
    // Handle the Escape key press event
  document.getElementById('loctext').innerHTML = ''
  }
}

  get_encodea(x: string, y: string) {
    this.mapsService.getAddress(x, y).subscribe(
      (data: any) => {
        console.log("heelo");
        console.log(data)
        if (this.fromToFocus == undefined && this.showSearchDirection == true) {
          this.fromLocation = data.addipid
          this.fromToFocus = 'to' //change the variable to shift focus to 'tolocation' varaible
        } else if (this.fromToFocus == 'to') {
          this.toLocation = data.addipid
        } else {
          this.locationText = data.addipid
          // check the returned response is local or global
          if (this.locationText.charAt(0) === 'G') {
            this.locationText = data.addipid.slice(1,);
            this.allhandlers.colorise(this,true,null,true);
            // remove the address bar
            this.addressText = ""
            this.showAddressBar = false;
          } else{
            this.allhandlers.colorise(this,true,null,false);
            // also populate address bar
              this.addressText = ""
              this.showAddressBar = true;
              this.addressText =[data["region/city"],data["district"],data["state"]].filter(Boolean).join(', ');

          }
          // colorise only the pincode
          
        }
      },
      (error: any) => {
        alert("Please Navigate to home and Authenticate.")
        window.location.href = 'https://verifypinid.auth.us-east-1.amazoncognito.com/login?client_id=6qd3i6390mia6qpdes4pemq26h&redirect_uri='+environment.redirect_url+'&response_type=code';
      }
    )
  }



  get_decodea(x: string, y: string) {    
    this.mapsService.getdecodebox(x, y).subscribe(
      (data: any) => {
        // console.log(data)
        if (data[0],data[1],data[2],data[3]==0){
          alert("Please enter the correct ADDIPID");
          return
        }
        let x_mercetor = parseFloat(data[0])
        let y_mercetor = parseFloat(data[2])

        let polygon = this.allhandlers.create_polygon_from_minmaxcoords(data,'mercator') as any
        console.log(polygon);
        (this.map.getSource('selectedCell') as GeoJSONSource).setData(polygon);  
        // Get the centroid of the polygon using Turf.js
        const centroid = turf.center(polygon) as any
        // this.map.panTo(centroid.geometry.coordinates);
        // add marker
        this.marker.remove();
        this.marker.setLngLat([centroid.geometry.coordinates[0],centroid.geometry.coordinates[1]]).addTo(this.map);
        this.map.flyTo({
          center: [centroid.geometry.coordinates[0],centroid.geometry.coordinates[1]],
          zoom: 17
        })      

        // get the high level address using Nominatim reverse geocoding service
        // this.geocode_location(centroid.geometry.coordinates);   
      },
      (error: any) => {
        alert("Please Navigate to home and Authenticate.")
        // window.location.href = 'https://verifypinid.auth.us-east-1.amazoncognito.com/login?client_id=6qd3i6390mia6qpdes4pemq26h&redirect_uri='+environment.redirect_url+'&response_type=code';
      }
    )
  }

  get_geocode(x: string) {
    this.mapsService.g_geocode(x).subscribe(
      data => {        
        let locs = data[0].geometry.location
        // this.map.setCenter([locs.lng, locs.lat])
        // add default zoom level to 16
        // this.map.setZoom(17) 
        //add marker
      this.marker.remove();
      this.marker.setLngLat([locs.lng, locs.lat]).addTo(this.map);
        this.map.flyTo({
          center: [locs.lng, locs.lat],
          zoom: 17
        })
      }
    )
  }

  geocode_location(x: string) {
    if(this.type=="omap" || this.type=="mmap"){
      this.mapsService.n_geocode(x).subscribe(
        data => {      
          // console.log(data);
          if("error" in data){
            this.addressText = ""
            this.showAddressBar = false;
            return
          }  
          let country = data['address'].country;
          let state = data['address'].state;
          let district = data['address'].state_district;
          let suburban = data['address'].suburb;
          let city = data['address'].city;
          let locality = data['address'].locality;
          let village = data['address'].village;
          this.showAddressBar = true;
          this.addressText =[locality,village,suburban,city,district,state,country].filter(Boolean).join(', ');
        }
      )
    }else if(this.type=="gmap"){
      this.mapsService.g_geocode(x,'reverse').subscribe(
        data => {
          if(data[0].address_components.length == 1){
            this.addressText = ""
            this.showAddressBar = false;
            return
          }       
          let locs = data[0].address_components
          let add = {"country":null,"locality":null,"administrative_area_level_3":null,"administrative_area_level_2":null,"administrative_area_level_1":null,"postal_code":null,"sublocality_level_2":null,"sublocality_level_1":null,"sublocality_level_3":null}
        
          for (let i = 0; i < locs.length; i++) {
            add[locs[i].types[0]] =  locs[i].long_name
           }
           this.showAddressBar = true;
           this.addressText =[add.sublocality_level_3,add.sublocality_level_2,add.sublocality_level_1,add.locality,add.administrative_area_level_3,add.administrative_area_level_2,add.administrative_area_level_1,add.country].filter(Boolean).join(',');
           console.log(this.addressText)
        }
      )
    }else{
        // console.log("nothing")
    }
  }

  getSearchLocations() {    
  
    // hide the floor input box if open
    this.disable_floorBox();

    this.locationText = document.getElementById('loctext').textContent;
    let cords = [] as any
    let letters = /[a-zA-Z]/g;
    let lettersExist = letters.test(this.locationText);

    // creating regex 
    let count_dots = this.locationText.split('.').length - 1
    if (count_dots >= 3) {
      this.allhandlers.colorise(this,false);
      this.get_decodea(this.locationText, this.countryselect.toLowerCase())
      return
    }

    //if letter exists then fire google api geocoding and center the map to that location

    if (lettersExist == true) {
      this.get_geocode(this.locationText)
      return
    }


    typeof this.locationText === 'string' ? cords = this.locationText.trim().split(',') : '';


    // this.map.setCenter([cords[1], cords[0]])
    this.map.flyTo({
      center: [cords[1], cords[0]],
      zoom: 17
    });
    // convert that to addipid by sending the location to encode
    let location = [cords[1], cords[0]] as any
    this.get_encodea(location[0],location[1])
    // also geocode the location
    // this.geocode_location(location)
    // add marker
    this.marker.remove();
    this.marker.setLngLat([cords[1], cords[0]]).addTo(this.map);

    let latlng = new mapboxgl.LngLat(cords[1], cords[0])
    // setTimeout(() => {
      // console.log(this.squareGrid)
    //   let point = turf.point([cords[1], cords[0]]);
    //   for (let i = 0; i < this.squareGrid.features.length; i++) {
    //     let ss = turf.booleanContains(this.squareGrid.features[i].geometry, point)
    //     if (ss == true) {
    //       (this.map.getSource('highlight_grid') as GeoJSONSource).setData(this.squareGrid.features[i].geometry);
    //       let box_cords = this.squareGrid.features[i].geometry.coordinates[0][0]
    //       // this.getGridInfo(null, box_cords[1], box_cords[0] + 0.0000225)
          // console.log(this.squareGrid.features[i])         
    //     }
    //   }
    // }, 200)
  }

    

  switchBasemap(event, val) {
    event.preventDefault()   

    switch (val) {
      case 'street': {   
        document.getElementById("satellitebasemap").style.zIndex = '1';
        document.getElementById("vectorbasemap").style.zIndex = '-9';     
        this.map.setPaintProperty('super-mesh','line-color','#a6a6a6');
        if(this.type == "mmap"){
          this.map.setLayoutProperty('mapbox-satellite-map', 'visibility', 'none');
        } else{
          this.map.setLayoutProperty('google-satellite-map', 'visibility', 'none');
          this.map.setLayoutProperty('google-street-map', 'visibility', 'none');
          this.map.setLayoutProperty('osm-satellite-map', 'visibility', 'none');
          this.map.setLayoutProperty('osm-street-map', 'visibility', 'none');

          this.map.setLayoutProperty(this.enable_street, 'visibility', 'visible');
        }
        break;
      }
      case 'satellite': {
        document.getElementById("satellitebasemap").style.zIndex = '-9';
        document.getElementById("vectorbasemap").style.zIndex = '1';
        this.map.setPaintProperty('super-mesh','line-color','black');        
        if(this.type == "mmap"){
          this.map.setLayoutProperty('mapbox-satellite-map', 'visibility', 'visible');
        } else{          
          this.map.setLayoutProperty('google-satellite-map', 'visibility', 'none');
          this.map.setLayoutProperty('google-street-map', 'visibility', 'none');
          this.map.setLayoutProperty('osm-satellite-map', 'visibility', 'none');
          this.map.setLayoutProperty('osm-street-map', 'visibility', 'none');

          this.map.setLayoutProperty(this.enable_satellite, 'visibility', 'visible');
        }
        break;
      }
      default: {
        document.getElementById("satellitebasemap").style.zIndex = '1';
        document.getElementById("vectorbasemap").style.zIndex = '-9';
        this.map.setPaintProperty('super-mesh','line-color','#a6a6a6');
        break;
      }
    }
  }

  getRoute() {
    if (this.fromLocation != null && this.toLocation != null) {
      let [fromLat, fromLong] = this.fromRoute
      let [toLat, toLong] = this.toRoute

      let fromlatlong = fromLat + ',' + fromLong
      let tolatlong = toLat + ',' + toLong

      window.open('https://www.google.com/maps/dir/' + fromlatlong + '/' + tolatlong + '/', '_blank');
      // console.log('https://www.google.com/maps/dir/' + fromlatlong + '/' + tolatlong + '/', '_blank');
    }

  }

  inputfocus(val) {
    if (val == "from") {
      this.fromToFocus = 'from'
    } else {
      this.fromToFocus = 'to'
    }
  }

  copyId() {
    const textarea = document.createElement('textarea');
    if (this.showSearchDirection) {
      textarea.value = `Starting Point : ${this.fromLocation}, Destination Point : ${this.toLocation}`;
    } else {
      textarea.value = this.locationText;
    }
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    textarea.remove();
  }

  shareTextOnWhatsApp() {
    if (this.showSearchDirection) {
      window.open(`https://wa.me/?text=${encodeURIComponent(`Starting Point : ${this.fromLocation}, Destination Point : ${this.toLocation}`)}`);
    } else {
      window.open(`https://wa.me/?text=${encodeURIComponent(this.locationText)}`);
    }
  }

  gpsLocation() {
    navigator.geolocation.getCurrentPosition(resp => {
      // this.map.setCenter([resp.coords.longitude, resp.coords.latitude])
      //add marker
      this.marker.remove();
      this.marker.setLngLat([resp.coords.longitude, resp.coords.latitude]).addTo(this.map);
      this.map.flyTo({
        center: [resp.coords.longitude, resp.coords.latitude],
        zoom: 18
      })
    // decode the location to addipid
    let location = [resp.coords.longitude, resp.coords.latitude] as any
    this.get_encodea(location[0],location[1])
    // // also geocode the location
    // this.geocode_location(location)
     },
     err => {
      //  console.log(err);
     });
  }

  zoomin(){
    this.map.zoomIn();
  }
  zoomout(){
    this.map.zoomOut();
  }

  enable_floorBox(){
    document.getElementById('selectGroupbox').style.display = 'block';
    document.getElementById('floorInput').style.display = 'block';
    document.getElementById('floorBtn').style.display = 'flex';
    document.getElementById('ignorefloorBtn').style.display = 'flex';
  }
  disable_floorBox(){
    document.getElementById('selectGroupbox').style.display = 'none';
    document.getElementById('floorInput').style.display = 'none';
    document.getElementById('floorBtn').style.display = 'none';
    document.getElementById('ignorefloorBtn').style.display = 'none';
  }

  ignoreFloor(){
    this.disable_floorBox();
  }

  
}