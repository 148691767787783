import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapsRoutingModule } from './maps-routing.module';
import { MapsComponent } from './components/maps/maps.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [MapsComponent],
  imports: [
    CommonModule,
    MapsRoutingModule,
    FormsModule,
  ]
})
export class MapsModule { }
