import * as turf from '@turf/turf';
import {
  Injectable
} from '@angular/core';
// call geocoder service thats installed



@Injectable({
  providedIn: 'root'
})

export class all_handlers {

  // call this function to get the centroid
  get_centroid(turfPolygon) {
    let centroid = turf.centroid(turfPolygon)
    return centroid
  }
  // get the cell information and pass the centroid of the cell to get addipid
  getCellInfo(e = null as any, comp) {
    // find the centroid of cell polygon and call the encode service
    let polygon = turf.polygon([comp.cell_polygon])
    let centroid = this.get_centroid(polygon) //call the custom centroid function
    let [x, y] = [centroid.geometry.coordinates[0], centroid.geometry.coordinates[1]]
    comp.get_encodea(x.toString(), y.toString())

    // Check if the user wants to extract for direction routing, fill the route variables 
    // initiate the fromToFocus variable and store the wgs84 location in respective variable
    comp.fromToFocus == undefined ? (comp.fromRoute = [y, x]) : comp.toRoute = [y, x]

  }

  create_polygon_from_minmaxcoords(data: any, crs: string) {
    if (crs == "mercator") {
      let min_xy = [data[0], data[2]]
      let max_xy = [data[1], data[3]]
      var min_loc = turf.toWgs84(turf.point([min_xy[0], min_xy[1]]))
      var max_loc = turf.toWgs84(turf.point([max_xy[0], max_xy[1]]))
      // Define minimum and maximum latitude and longitude coordinates
    }
    let minLng = min_loc.geometry.coordinates[0];
    let minLat = min_loc.geometry.coordinates[1];
    let maxLng = max_loc.geometry.coordinates[0];
    let maxLat = max_loc.geometry.coordinates[1];
    // Create an array of coordinates for the polygon
    let polygonCoords = [
      [minLng, minLat],
      [maxLng, minLat],
      [maxLng, maxLat],
      [minLng, maxLat],
      [minLng, minLat]
    ];
    return turf.polygon([polygonCoords]);
  }

  colorise(comp,encode=true,floorNo=null,global=false) {
    let content = ''
    if(encode==true){
      content = comp.locationText;
    } else if (floorNo != null){
      if (parseInt(floorNo) > 0){ floorNo = '+'+floorNo}
      if (parseInt(floorNo) == 0){ floorNo = ' '+floorNo}
      document.getElementById('loctext').innerHTML += '.'+floorNo;
      content = document.getElementById('loctext').innerHTML;
      console.log(content)
    } else{
      content = document.getElementById('loctext').innerHTML;
      console.log(content)
    } 
 
    // Define a regex to match the first set of numbers before the first dot
    // const regex = /^(\d+)\./;
    const regex = /^([^.]+)\..*\.(.*)$/;
    const match = regex.exec(content);
    
    if (match) {
      // Wrap the matched text with a span element with a class
      let color = 'black'
      if (global == true){
        color = '#7030A0'
      }
      const pinColor = `<span style="color:`+color+`">${match[1]}</span>`;
      // Replace the matched text with the blue text
      let newText = content.replace(match[1], pinColor);
      // take floor no and colorise
      if (floorNo != null) {
        const floorText = `<span style="color:blue">${match[2]}</span>`;
        newText = content.replace(match[2], floorText);
      }
      
      // Set the updated text as the div content      
      document.getElementById('loctext').innerHTML = newText;
      console.log(document.getElementById('loctext').innerHTML)
      // Update the locationText variable
      // comp.locationText = newText;
    }
  }


}
