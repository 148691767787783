import { Component,AfterViewInit } from '@angular/core';
declare const SwaggerUIBundle: any;
@Component({
  selector: 'app-swagger-ui',
  templateUrl: './swagger-ui.component.html',
  styleUrls: ['./swagger-ui.component.scss']
})
export class SwaggerUIComponent implements AfterViewInit  {

  constructor() { }

  ngAfterViewInit(): void {
    const ui = SwaggerUIBundle({
      url: 'assets/api_docs/addipid_api.json', // Replace with the actual path to your Swagger JSON file
      dom_id: '#swagger-ui'
    });
  } 

}
