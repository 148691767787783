import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-redirect-component',
  templateUrl: './redirect-component.component.html',
  styleUrls: ['./redirect-component.component.scss']
})
export class RedirectComponentComponent implements OnInit {

  constructor() { }
  
  ngOnInit() {
    window.location.href = 'https://verifypinid.auth.us-east-1.amazoncognito.com/login?client_id=6qd3i6390mia6qpdes4pemq26h&redirect_uri='+environment.redirect_url+'&response_type=code';

  }

}
