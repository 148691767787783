// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  redirect_url: 'https://pinid.in/auth',
  // redirect_url: 'http://localhost:4200/auth',
  // dfsdfs
  // change above
  mapbox: {
    accessToken: 'pk.eyJ1IjoiYWRkaXBpZCIsImEiOiJjbGIzM3kzeTMwYXR0M3BwZXhoY3UxdGRpIn0.3DXYaYsDe7bUA6o-BbvUXg'  
    // pk.eyJ1IjoibWFwYm94LW9mZmljaWFsIiwiYSI6ImNrcHBnMG5yMDA2eWgyb2s5MDI4NG10OXAifQ.S65o8bcu6oqsp6HQnWYJfw  (other)
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
