import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  environment
} from 'src/environments/environment';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router) {
    
    this.checkCode();
  }
  ngOnInit() {
   
  }
  
  // fetch the code from the url and start checking
  checkCode() {
    const code = this.route.snapshot.queryParamMap.get('code');
    if (code) {
      // Code exists, proceed with fetching access token and token ID
      this.fetchTokens(code);
    } else {
      // Code does not exist
      console.log('Code does not exist in the callback URL');
    }
  }

  // use this function to fetch tokens based on the code value provided
  
  fetchTokens(code: string) {
    // alert(code)
    const body = {
      grant_type: 'authorization_code',
      client_id: '6qd3i6390mia6qpdes4pemq26h',
      redirect_uri: environment.redirect_url,
      code: code,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic NnFkM2k2MzkwbWlhNnFwZGVzNHBlbXEyNmg6M3RzZjM1N3I5YTUydGYyaHMyZzNlNGRkNXFuOG12ZTZoMzZmbXRlaXRlM3RyMXZrMnBt'
    });

    const options = {
      headers: headers,
    };
    
    // Make the API call to your AWS Cognito token endpoint
    this.http.post('https://verifypinid.auth.us-east-1.amazoncognito.com/oauth2/token', this.serializeParams(body), options)
      .subscribe(
        (response: any) => {
          const accessToken = response.access_token;
          const tokenId = response.id_token;
          

          // Handle the tokens as per your application's requirements
          console.log('Access Token:', accessToken);
          console.log('Token ID:', tokenId);
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('tokenId', tokenId);
          this.router.navigate(['/pinonmap'])

          // You may want to store the tokens in local storage or use them for authenticated requests
        },
        (error: any) => {
          console.error('Error fetching tokens:', error);
        }
      );
  }
// helper function to serialise the parameters
  serializeParams(params: any): string {
    return Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
  }

}
