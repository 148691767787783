import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Iencodea, Ilocintel, Ilocmodification } from '../modules/maps/components/maps';
import { Igeocode } from '../modules/maps/components/maps';
import { Idecode } from '../modules/maps/components/maps';
import { Igrid } from '../modules/maps/components/maps';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class MapsService {

  private _url: string = "assets/data/creategrid.json"

  constructor(private http: HttpClient) { }
   
  
  //get method -http call by passing location text as query param
  // getLocation(loc: string) {
  //   return this.http.get('https://reqres.in/api/users?page='+loc)
  // }

  //post method - http call by passing location text in request body
  // getAddress(xcord:string,ycord:string):Observable<Iencodea[]> {
  //   return this.http.get<Iencodea[]>('https://cye9x37v6j.execute-api.us-east-1.amazonaws.com/demo/encodea?x='+xcord+'&y='+ycord).pipe(map((data: Iencodea) => data),
  //   catchError(this.handleError))
   
  // }
  // handleError(handleError: any): import("rxjs").OperatorFunction<unknown, Iencodea[]> {
  //   throw new Error('Method not implemented.');
  // }

// i have to use accesstoken. but some issue. so using/replaced tokenid in the brackets
  getAddress(xcord:string,ycord:string){
    const accessToken = localStorage.getItem('tokenId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'access-token': accessToken, // Include your access token if required
    });
    return this.http.get<Iencodea>('https://cye9x37v6j.execute-api.us-east-1.amazonaws.com/demo/encodea?x='+xcord+'&y='+ycord, { headers }).pipe(
    //  map((data: Iencodea) => data.addipid.substring(0, data.addipid.lastIndexOf(".")))
    map((data: Iencodea) => data),
    catchError((error: HttpErrorResponse) => {
      // Handle errors and check status code here
      console.error('Error:', error);
      console.error('Status code:', error.status);
      if (error.status == 429){
        alert("Sorry.. Number of requests have been exceeded")
        window.location.href = 'https://verifypinid.auth.us-east-1.amazoncognito.com/login?client_id=6qd3i6390mia6qpdes4pemq26h&redirect_uri='+environment.redirect_url+'&response_type=code';      }
      return throwError(error); // Re-throw the error to propagate to the caller
    })

    //  catchError(this.handleError),     
  );
  }
  getdecodebox(addipid:string,country:string){
    const accessToken = localStorage.getItem('tokenId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'access-token': accessToken, // Include your access token if required
    });
    return this.http.get<Idecode>('https://cye9x37v6j.execute-api.us-east-1.amazonaws.com/demo/decodea?addipid='+addipid+'&country='+country, { headers }).pipe(
     map((data: Idecode) => data.bbox)
    //  catchError(this.handleError),     
  );
  }


  // direct geocoding service
  pincode_finder(stateName:any){
    const accessToken = localStorage.getItem('tokenId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'access-token': accessToken, // Include your access token if required
    });
    
  const queryParams = new HttpParams({ fromObject: { state: stateName } });

  return this.http.get<JSON>('https://cye9x37v6j.execute-api.us-east-1.amazonaws.com/demo/pincode_finder', { headers, params: queryParams }).pipe(
    map((data: JSON) => data)
  );
}

  // direct geocoding service
  g_geocode(loc:any, type="direct"){
    const accessToken = localStorage.getItem('tokenId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'access-token': accessToken, // Include your access token if required
    });
    
  const queryParams = new HttpParams({ fromObject: { loc: loc } });

  return this.http.get<Igeocode>('https://cye9x37v6j.execute-api.us-east-1.amazonaws.com/demo/direct_geocode', { headers, params: queryParams }).pipe(
    map((data: Igeocode) => data.res)
  );
}
  // g_geocode(loc:any, type="direct"){
  //   const accessToken = localStorage.getItem('tokenId');
  //   // console.log(accessToken)
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'access-token': accessToken, // Include your access token if required
  //     'Accept': 'application/json',
  //   });
  //   // console.log(loc)
  //   // let lat = loc.split(",")[0].trim()
  //   // let long = loc.split(",")[1].trim()
  //   let lat = loc[1];
  //   let long = loc[0];
  //   // direct or reverse
  //   if(type == "direct"){
  //     return this.http.get<Igeocode>('https://maps.googleapis.com/maps/api/geocode/json?address='+loc+'&key=AIzaSyDeyjxJN2VL3EqJVVQZOXBbCZe-ngybksw').pipe(
  //       map((data: Igeocode) => data.results)
  //      //  catchError(this.handleError),     
  //    );
  //   // return this.http.get<Igeocode>('https://cye9x37v6j.execute-api.us-east-1.amazonaws.com/demo/direct_geocode?loc=' + loc, { headers }).pipe( 
  //   //   map((data: Igeocode) => data.res));
  //   //   locator.subscribe(res => {
  //   //     console.log(res);  // This will log data.res to the console
  //   // });
  //   // return this.http.get<Igeocode>('https://cye9x37v6j.execute-api.us-east-1.amazonaws.com/demo/direct_geocode?loc=' + loc, { headers }).pipe(map((data: Igeocode) => data.res));
    
  
  //   } else {
  //     return this.http.get<Igeocode>('https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+long+'&key=AIzaSyDeyjxJN2VL3EqJVVQZOXBbCZe-ngybksw').pipe(
  //       map((data: Igeocode) => data.results)
  //      //  catchError(this.handleError),     
  //    );
  //   //  return this.http.get<Igeocode>('https://cye9x37v6j.execute-api.us-east-1.amazonaws.com/demo/reverse_geocode?y='+lat+'&x='+long, { headers }).pipe(
  //   //   map((data: Igeocode) => data.res)
  //   //  //  catchError(this.handleError),     
  //   //   );
  //   }
  // }

  loc_intel(long:any,lat:any,add_data:any){
    const accessToken = localStorage.getItem('tokenId');
    // console.log(accessToken)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'access-token': accessToken, // Include your access token if required
    });
    

    return this.http.get<Ilocintel>('https://cye9x37v6j.execute-api.us-east-1.amazonaws.com/demo/loc_intel?x='+long+'&y='+lat+'&add_data='+add_data, { headers }).pipe(
      map((data: Ilocintel) => data.result)
   );

  }

  loc_modification(long:any,lat:any,modified_address_data:any){
    const accessToken = localStorage.getItem('tokenId');
    const add_data = {data: modified_address_data}
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'access-token': accessToken, // Include your access token if required
    });
    

     // Convert the JSON object to a properly encoded query parameter string
     console.log(long,lat)
  const queryParams = new HttpParams({ fromObject: { x: long, y: lat, add_data: JSON.stringify(add_data)} });
console.log(queryParams);
  return this.http.get<Ilocmodification>('https://cye9x37v6j.execute-api.us-east-1.amazonaws.com/demo/loc_modification', { headers, params: queryParams }).pipe(
    map((data: Ilocmodification) => data.result)
  );
}
 
  // Nominatm geocoding service.its opensource
  n_geocode(coords:any, type="reverse"){
    if(type == "reverse"){
      console.log('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat='+coords[1]+'&lon='+coords[0]+'&zoom=18&accept-language=english')
      return this.http.get<Igeocode>('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat='+coords[1]+'&lon='+coords[0]+'&zoom=18&accept-language=english').pipe(
        map((data: Igeocode) => data)
       //  catchError(this.handleError),     
     );
    }  
  }
  getGrid(minx:string,miny:string,maxx:string,maxy:string){
    const accessToken = localStorage.getItem('tokenId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'access-token': accessToken, // Include your access token if required
    });
    return this.http.get<Igrid>('https://cye9x37v6j.execute-api.us-east-1.amazonaws.com/demo/creategrid?minx='+minx+'&miny='+miny+'&maxx='+maxx+'&maxy='+maxy, { headers }).pipe(
    //  map((data: Igrid) => data.addipid.substring(0, data.addipid.lastIndexOf(".")))
    map((data: Igrid) => data.results)
    //  catchError(this.handleError),     
  );
  }

  //   getGrid(minx:string,miny:string,maxx:string,maxy:string){
  //   return this.http.get<Igrid>(this._url).pipe(
  //   //  map((data: Igrid) => data.addipid.substring(0, data.addipid.lastIndexOf(".")))
  //   map((data: Igrid) => data.results)
  //   //  catchError(this.handleError),     
  // );
  // }
  
}
