import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MapsComponent } from './modules/maps/components/maps/maps.component';
import { AuthComponent } from './auth/auth.component';
import { RedirectComponentComponent } from './redirect-component/redirect-component.component';
import { SwaggerUIComponent } from './swagger-ui/swagger-ui.component';
import { PinOnMapComponent } from './pin-on-map/pin-on-map.component';

const routes: Routes = [
  { path: 'auth', component: AuthComponent },
  {
    path: 'maps/:type',
    loadChildren: () => import('./modules/maps/maps.module').then(m => m.MapsModule)
  },
  {
    path: '',
    component: RedirectComponentComponent
    // redirectTo: '/maps/mmap',
    // pathMatch: 'full'
  },
  {path:'pinonmap', component: PinOnMapComponent},
  {path: 'api-docs', component: SwaggerUIComponent},
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
